function increment(obj, id_grp) {
  obj.parentNode.parentNode.querySelector('input[type=number]').stepUp();

  let id_prod = $(obj).attr("my_qty_id_product");
  let qte = parseInt($(".custom_qty_" + id_grp).val());
  let max = parseInt($(".custom_qty_" + id_grp).attr('max2'));

  if (max == 0) {
    $(".custom_qty_" + id_grp).val(1);
    $(".qty_" + id_grp).val(1);
    createAlert('Nie możesz dodać tego produktu do koszyka.', 'info');
    return;
  }

  if ((qte + 1) > max) {
    $(".custom_qty_" + id_grp).val(max);
    $(".qty_" + id_grp).val($(".custom_qty_" + id_grp).val());
    createAlert('Maksymalnie możesz dodać do koszyka ' + max + ' szt.', 'info');
    return;
  }

  $(".qty_" + id_grp).val($(".custom_qty_" + id_grp).val());
  //check_quanitity_selector(id_prod, qte);
}

function decrement(obj, id_grp) {
  obj.parentNode.parentNode.querySelector('input[type=number]').stepDown();
  $(".qty_" + id_grp).val($(".custom_qty_" + id_grp).val());

  let id_prod = $(obj).attr("my_qty_id_product");
  let qte = $(".qty_" + id_grp).val();

  //check_quanitity_selector(id_prod, qte);
}

function check_quanitity_selector(id_product, current_quantity) {

  $.ajax({
    type: "post",
    data: 'id_product=' + id_product + '&current_quantity=' + current_quantity + '&func=CCQ',
    url: "/manager/check_availability.php",
    success: function (data) {
      data = JSON.parse(data);

      $(".price_" + data.id_product_group).html(data.product_price);
      if (!data.available_quantity) {
        //$(".add_to_cart_"+data.id_product_group).attr("disabled", true);
        $(".custom_qty_" + data.id_product_group).val(data.product_max_quantity);
        $(".qty_" + data.id_product_group).val(data.product_max_quantity);

      } else {
        $(".add_to_cart_" + data.id_product_group).attr("disabled", false);
        $(".qty_" + data.id_product_group).val(current_quantity);
        $(".custom_qty_" + data.id_product_group).val(data.current_quantity);
      }

      if (data.product_has_discount == false) {
        $(".discount_" + data.id_product_group).hide();
        $(".regular-price_promo_" + data.id_product_group).hide();
        $(".regular-price_" + data.id_product_group).hide();

        $(".price_" + data.id_product_group).removeClass("has_discount");
      } else {
        $(".discount_" + data.id_product_group).show();
        $(".discount-amount_" + data.id_product_group).html(data.discount_ammount);
        $(".regular-price_promo_" + data.id_product_group).show();
        $(".regular-price_" + data.id_product_group).show();

        $(".price_" + data.id_product_group).addClass("has_discount");
        $(".regular-price_" + data.id_product_group).html(data.price_no_discount);
        prom_exist = false;
        if ($('.regular-price_promo_' + data.id_product_group).length == 0 && !prom_exist)         // use this if you are using id to check
        {
          prom_exist = true;
          $(".price_promo_" + data.id_product_group).after('<span class="regular-price regular-price_promo_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
          $(".regular-price_promo_" + data.id_product_group).after('<span class="discount discount_' + data.id_product_group + ' discount-amount">Oszczędasz <strong class="discount-amount' + data.id_product_group + '">' + data.discount_ammount + '</strong></span>');
        }
      }
    }
  });
}

function change_variants(id_product, prod_exist, id_group_variant) {

  if (typeof id_product == "undefined") {
    return;
  }

  if (prod_exist == true) {
    $.ajax({
      type: "post",
      data: 'id_product=' + id_product + '&func=CV',
      url: "/manager/change_home_variants.php",
      success: function (data) {
        data = JSON.parse(data);

        $(".price_" + data.id_product_group).html(data.product_price);

        $(".image_" + data.id_product_group).attr("src", data.product_image);
        $(".image_link_" + data.id_product_group).attr("href", data.product_url);

        $(".product_title_" + data.id_product_group).attr("href", data.product_url);

        $(".custom_qty_" + data.id_product_group).attr("max", data.product_max_quantity);
        $(".custom_qty_" + data.id_product_group).attr("max2", data.product_max_quantity);

        $(".custom_qty_" + data.id_product_group).attr("my_id_product", id_product);
        $(".custom_qty_inc_" + data.id_product_group).attr("my_qty_id_product", id_product);
        $(".custom_qty_" + data.id_product_group).val(1);

        if (!data.available_quantity) {
          $(".add_to_cart_" + data.id_product_group).attr("disabled", true);
          $(".product-empty").show();
          $(".product_empty_" + id_product).removeClass('hidden');
          $("#button-container-" + data.id_product_group).addClass('button-container-disabled');
        } else {
          $(".add_to_cart_" + data.id_product_group).attr("disabled", false);
          $(".product-empty").hide();
          $(".product_empty_" + id_product).addClass('hidden');
          $("#button-container-" + data.id_product_group).removeClass('button-container-disabled');
        }

        if (data.product_has_discount == false) {
          $(".discount_" + data.id_product_group).hide();
          $(".regular-price_promo_" + data.id_product_group).hide();
          $(".regular-price_" + data.id_product_group).hide();
          $(".regular-price_promo_carr_" + data.id_product_group).hide();

          $(".price_" + data.id_product_group).removeClass("has_discount");
        } else {
          $(".discount_" + data.id_product_group).show();
          $(".discount-amount_" + data.id_product_group).html(data.discount_ammount);
          $(".regular-price_promo_" + data.id_product_group).show();
          $(".regular-price_" + data.id_product_group).show();
          $(".regular-price_promo_carr_" + data.id_product_group).show();

          $(".price_" + data.id_product_group).addClass("has_discount");
          $(".regular-price_" + data.id_product_group).html(data.price_no_discount);
          let prom_exist = false;
          let prom_exist_carr = false;
          if ($('.regular-price_promo_' + data.id_product_group).length == 0 && !prom_exist)         // use this if you are using id to check
          {
            prom_exist = true;
            $(".price_promo_" + data.id_product_group).after('<span class="regular-price regular-price_promo_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
            $(".regular-price_promo_" + data.id_product_group).after('<span class="discount discount_' + data.id_product_group + ' discount-amount">Oszczędasz <strong class="discount-amount' + data.id_product_group + '">' + data.discount_ammount + '</strong></span>');
          }
          if ($('.regular-price_promo_carr_' + data.id_product_group).length == 0 && !prom_exist_carr)         // use this if you are using id to check
          {
            prom_exist_carr = true;
            $(".price_promo_carr_" + data.id_product_group).after('<span class="regular-price regular-price_promo_carr_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
          }
        }

        if (data.omnibus.cena != '') {
          $("#omnibus_" + data.id_product_group).html('<div>Było ' + data.omnibus.cena + '</div><div>' + data.omnibus.data + '</div>');
          $("#omnibus_" + data.id_product_group).show();
        } else {
          $("#omnibus_" + data.id_product_group).html('');
          $("#omnibus_" + data.id_product_group).hide();
        }

        $(".product_page_product_id_" + data.id_product_group).val(id_product);
        $(".product_title_" + data.id_product_group).html(data.product_name);


        if (data.label_promo != "" && data.label_hit != "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag prom" >' + data.label_promo + '</li>' + '<li class="product-flag hit">' + data.label_hit + '</li>');

        } else if (data.label_promo == "" && data.label_hit != "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag hit">' + data.label_hit + '</li>');

        } else if (data.label_promo != "" && data.label_hit == "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag prom">' + data.label_promo + '</li>');
        } else if (data.label_promo == "" && data.label_hit == "") {
          $(".flag_" + data.id_product_group).html('')
        }

        if (data.label_new != "" && data.label_best != "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new" >' + data.label_new + '</li>' + '<li class="product-flag best">' + data.label_best + '</li>');

        } else if (data.label_new == "" && data.label_best != "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag best">' + data.label_best + '</li>');

        } else if (data.label_new != "" && data.label_best == "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new">' + data.label_new + '</li>');
        } else if (data.label_new == "" && data.label_best == "") {
          $(".flag_extra_" + data.id_product_group).html('')
        }

      }
    });
    return -1
  }

  if (document.getElementById("select_product_" + id_product) != null) {

    $('.select_product_' + id_product).live('change', function (e) {

      id_product = e.target.options[e.target.selectedIndex].value;
      $(".select_grp_" + id_group_variant).val(id_product);

      $.ajax({
        type: "post",
        data: 'id_product=' + id_product + '&func=CV',
        url: "/manager/change_home_variants.php",
        success: function (data) {
          data = JSON.parse(data);

          $(".price_" + data.id_product_group).html(data.product_price);

          $(".image_" + data.id_product_group).attr("src", data.product_image);
          $(".image_link_" + data.id_product_group).attr("href", data.product_url);

          $(".product_title_" + data.id_product_group).attr("href", data.product_url);
          //alert(data.product_image);

          $(".custom_qty_" + data.id_product_group).attr("max", data.product_max_quantity);
          $(".custom_qty_" + data.id_product_group).attr("max2", data.product_max_quantity);

          $(".custom_qty_" + data.id_product_group).attr("my_id_product", id_product);
          $(".custom_qty_inc_" + data.id_product_group).attr("my_qty_id_product", id_product);
          $(".custom_qty_" + data.id_product_group).val(1);

          if (!data.available_quantity) {
            $(".add_to_cart_" + data.id_product_group).attr("disabled", true);
            $(".product-empty").show();
            $(".product_empty_" + id_product).removeClass('hidden');
            $("#button-container-" + data.id_product_group).addClass('button-container-disabled');
          } else {
            $(".add_to_cart_" + data.id_product_group).attr("disabled", false);
            $(".product-empty").hide();
            $(".product_empty_" + id_product).addClass('hidden');
            $("#button-container-" + data.id_product_group).removeClass('button-container-disabled');
          }

          if (data.product_has_discount == false) {
            $(".discount_" + data.id_product_group).hide();
            $(".regular-price_promo_" + data.id_product_group).hide();
            $(".regular-price_" + data.id_product_group).hide();
            $(".regular-price_promo_carr_" + data.id_product_group).hide();

            $(".price_" + data.id_product_group).removeClass("has_discount");
          } else {
            $(".discount_" + data.id_product_group).show();
            $(".discount-amount_" + data.id_product_group).html(data.discount_ammount);
            $(".regular-price_promo_" + data.id_product_group).show();
            $(".regular-price_" + data.id_product_group).show();
            $(".regular-price_promo_carr_" + data.id_product_group).show();

            $(".price_" + data.id_product_group).addClass("has_discount");
            $(".regular-price_" + data.id_product_group).html(data.price_no_discount);
            let prom_exist = false;
            let prom_exist_carr = false;
            if ($('.regular-price_promo_' + data.id_product_group).length == 0 && !prom_exist)         // use this if you are using id to check
            {
              prom_exist = true;
              $(".price_promo_" + data.id_product_group).after('<span class="regular-price regular-price_promo_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
              $(".regular-price_promo_" + data.id_product_group).after('<span class="discount discount_' + data.id_product_group + ' discount-amount">Oszczędasz <strong class="discount-amount' + data.id_product_group + '">' + data.discount_ammount + '</strong></span>');
            }
            if ($('.regular-price_promo_carr_' + data.id_product_group).length == 0 && !prom_exist_carr)         // use this if you are using id to check
            {
              prom_exist_carr = true;
              $(".price_promo_carr_" + data.id_product_group).after('<span class="regular-price regular-price_promo_carr_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
            }
          }

          if (data.omnibus.cena != '') {
            $("#omnibus_" + data.id_product_group).html('<div>Było ' + data.omnibus.cena + '</div><div>' + data.omnibus.data + '</div>');
            $("#omnibus_" + data.id_product_group).show();
          } else {
            $("#omnibus_" + data.id_product_group).html('');
            $("#omnibus_" + data.id_product_group).hide();
          }

          $(".product_page_product_id_" + data.id_product_group).val(id_product);
          $(".product_title_" + data.id_product_group).html(data.product_name);


          if (data.label_promo != "" && data.label_hit != "") {
            $(".flag_" + data.id_product_group).html('<li class="product-flag prom" >' + data.label_promo + '</li>' + '<li class="product-flag hit">' + data.label_hit + '</li>');

          } else if (data.label_promo == "" && data.label_hit != "") {
            $(".flag_" + data.id_product_group).html('<li class="product-flag hit">' + data.label_hit + '</li>');

          } else if (data.label_promo != "" && data.label_hit == "") {
            $(".flag_" + data.id_product_group).html('<li class="product-flag prom">' + data.label_promo + '</li>');
          } else if (data.label_promo == "" && data.label_hit == "") {
            $(".flag_" + data.id_product_group).html('')
          }

          if (data.label_new != "" && data.label_best != "") {
            $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new" >' + data.label_new + '</li>' + '<li class="product-flag best">' + data.label_best + '</li>');

          } else if (data.label_new == "" && data.label_best != "") {
            $(".flag_extra_" + data.id_product_group).html('<li class="product-flag best">' + data.label_best + '</li>');

          } else if (data.label_new != "" && data.label_best == "") {
            $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new">' + data.label_new + '</li>');
          } else if (data.label_new == "" && data.label_best == "") {
            $(".flag_extra_" + data.id_product_group).html('')
          }

        }
      });
    });
  } else {
    $.ajax({
      type: "post",
      data: 'id_product=' + id_product + '&func=CV',
      url: "/manager/change_home_variants.php",
      success: function (data) {
        data = JSON.parse(data);

        $(".price_" + data.id_product_group).html(data.product_price);

        $(".image_" + data.id_product_group).attr("src", data.product_image);
        $(".image_link_" + data.id_product_group).attr("href", data.product_url);

        $(".product_title_" + data.id_product_group).attr("href", data.product_url);
        //alert(data.product_image);

        $(".custom_qty_" + data.id_product_group).attr("max", data.product_max_quantity);
        $(".custom_qty_" + data.id_product_group).attr("max2", data.product_max_quantity);

        $(".custom_qty_" + data.id_product_group).attr("my_id_product", id_product);
        $(".custom_qty_inc_" + data.id_product_group).attr("my_qty_id_product", id_product);
        $(".custom_qty_" + data.id_product_group).val(1);


        if (!data.available_quantity) {
          $(".add_to_cart_" + data.id_product_group).attr("disabled", true);
          $(".product-empty").show();
          $(".product_empty_" + data.id_product_group).removeClass('hidden');
          $("#button-container-" + data.id_product_group).addClass('button-container-disabled');
        } else {
          $(".add_to_cart_" + data.id_product_group).attr("disabled", false);
          $(".product-empty").hide();
          $(".product_empty_" + data.id_product_group).addClass('hidden');
          $("#button-container-" + data.id_product_group).removeClass('button-container-disabled');
        }

        if (data.product_has_discount == false) {
          $(".discount_" + data.id_product_group).hide();
          $(".regular-price_promo_" + data.id_product_group).hide();
          $(".regular-price_" + data.id_product_group).hide();
          $(".regular-price_promo_carr_" + data.id_product_group).hide();

          $(".price_" + data.id_product_group).removeClass("has_discount");
        } else {
          $(".discount_" + data.id_product_group).show();
          $(".discount-amount_" + data.id_product_group).html(data.discount_ammount);
          $(".regular-price_promo_" + data.id_product_group).show();
          $(".regular-price_" + data.id_product_group).show();
          $(".regular-price_promo_carr_" + data.id_product_group).show();

          $(".price_" + data.id_product_group).addClass("has_discount");
          $(".regular-price_" + data.id_product_group).html(data.price_no_discount);
          let prom_exist = false;
          let prom_exist_carr = false;
          if ($('.regular-price_promo_' + data.id_product_group).length == 0 && !prom_exist)         // use this if you are using id to check
          {
            prom_exist = true;
            $(".price_promo_" + data.id_product_group).after('<span class="regular-price regular-price_promo_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
            $(".regular-price_promo_" + data.id_product_group).after('<span class="discount discount_' + data.id_product_group + ' discount-amount">Oszczędasz <strong class="discount-amount' + data.id_product_group + '">' + data.discount_ammount + '</strong></span>');
          }
          if ($('.regular-price_promo_carr_' + data.id_product_group).length == 0 && !prom_exist_carr)         // use this if you are using id to check
          {
            prom_exist_carr = true;
            $(".price_promo_carr_" + data.id_product_group).after('<span class="regular-price regular-price_promo_carr_' + data.id_product_group + '">' + data.price_no_discount + '</span>');
          }
        }

        if (data.omnibus.cena != '') {
          $("#omnibus_" + data.id_product_group).html('<div>Było ' + data.omnibus.cena + '</div><div>' + data.omnibus.data + '</div>');
          $("#omnibus_" + data.id_product_group).show();
        } else {
          $("#omnibus_" + data.id_product_group).html('');
          $("#omnibus_" + data.id_product_group).hide();
        }

        $(".product_page_product_id_" + data.id_product_group).val(id_product);
        $(".product_title_" + data.id_product_group).html(data.product_name);


        if (data.label_promo != "" && data.label_hit != "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag prom" >' + data.label_promo + '</li>' + '<li class="product-flag hit">' + data.label_hit + '</li>');

        } else if (data.label_promo == "" && data.label_hit != "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag hit">' + data.label_hit + '</li>');

        } else if (data.label_promo != "" && data.label_hit == "") {
          $(".flag_" + data.id_product_group).html('<li class="product-flag prom">' + data.label_promo + '</li>');
        } else if (data.label_promo == "" && data.label_hit == "") {
          $(".flag_" + data.id_product_group).html('')
        }

        if (data.label_new != "" && data.label_best != "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new" >' + data.label_new + '</li>' + '<li class="product-flag best">' + data.label_best + '</li>');

        } else if (data.label_new == "" && data.label_best != "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag best">' + data.label_best + '</li>');

        } else if (data.label_new != "" && data.label_best == "") {
          $(".flag_extra_" + data.id_product_group).html('<li class="product-flag new">' + data.label_new + '</li>');
        } else if (data.label_new == "" && data.label_best == "") {
          $(".flag_extra_" + data.id_product_group).html('')
        }

      }
    });
  }
}

$(document).on("input", ".product-quantity-spin-custom", function () {
  let v = parseInt($(this).val());
  let max = parseInt($(this).attr('max2'));

  if (v > max) {
    $(this).val(max);
    createAlert('Maksymalnie możesz dodać do koszyka ' + max + ' szt.', 'info');
  }

});

/*============================================================
    Lala Alert 0.1
    By lalaman
=============================================================*/
var alertExists = false;

function createAlert(message, status, timeout = 0) {
  if (alertExists == true) {
    return;
  }

  alertExists = true;
  //Used to determine whether to remove setTimeout or not
  var timeout_check;

  //Create alert element
  var alert = document.createElement("div");
  alert.className += "animation-target lala-alert ";

  //Attach correct colour to alert
  var status_class = "alert-" + status + " ";
  alert.className += status_class;

  //Create close button
  var close_button = document.createElement("span");
  close_button.className += " close-alert-x glyphicon glyphicon-remove";

  close_button.addEventListener("click", function () {
    var parent = this.parentNode;
    parent.parentNode.removeChild(parent);
  });

  //Add message and close button
  alert.innerHTML = message;
  alert.appendChild(close_button);

  //Prepend new alert to container
  var alert_wrapper = document.getElementById("lala-alert-wrapper");
  alert_wrapper.insertBefore(alert, alert_wrapper.children[0]);

  //If they haven't clicked close within the timeout period, fade out and remove element
  timeout_check = setTimeout(function () {
    var parent = alert;
    parent.className += " fade-out";
    if (parent.parentNode) {
      timeout_check = setTimeout(function () {
        parent.parentNode.removeChild(parent)
      }, 500);
    }
    alertExists = false;
  }, 2500);
};

window.createAlert = createAlert;
window.increment = increment;
window.decrement = decrement;
window.check_quanitity_selector = check_quanitity_selector;
window.change_variants = change_variants;
